import React from "react";
import Layout from "../components/Layout";

const PageNotFound = () => {
  return (
    <Layout title="404 - Page Not Found">
      <div className="container">
        <div className="page-not-found">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <h1 className="text-center">Oops!</h1>
              <p className="text-center status">404 - Page not found</p>
              <p className="text-center">
                The page you are looking for might have been removed had its
                name changed or is temporarily unavailable
              </p>
              <p className="text-center">
                <a href="/" className="btn btn-danger back-to-home">
                  Back to Home
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;
