import React from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Category from "./pages/Category";
import LeaderBoard from "./pages/LeaderBoard";
import { filterAction } from "./actions/filter.action";
import SubCategory from "./pages/SubCategory";
import Detail from "./pages/Detail";
import SearchResult from "./pages/SearchResult";
import PageNotFound from "./pages/PageNotFound";
import SingleSubCategory from "./pages/SingleSubCategory";
import Privacy from "./pages/Privacy";
import Term from "./pages/Term";

export default function App() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(filterAction.fetch());
  }, [dispatch]);

  return (
    <div className="App">
      <Router basename={process.env.REACT_APP_BASENAME}>
        <Switch>
          <Route path="/" render={(props) => <Home {...props} />} exact />
          <Route
            path="/winner-archives"
            render={(props) => <Category {...props} />}
            exact
          />
          <Route
            path="/winner-archives/:year"
            render={(props) => <Category {...props} />}
            exact
          />
          <Route
            path="/winner-archives/:year/:category"
            render={(props) => <SubCategory {...props} />}
            exact
          />
          <Route
            path="/winner-archives/:year/:category/:subCategory"
            render={(props) => <SingleSubCategory {...props} />}
            exact
          />
          <Route
            path="/winner-archives/:year/:category/:subCategory/:id"
            render={(props) => <Detail {...props} />}
            exact
          />
          <Route
            path="/search"
            render={(props) => <SearchResult {...props} />}
            exact
          />
          <Route
            path="/leader-board-update"
            render={(props) => <LeaderBoard {...props} />}
            exact
          />
          <Route
            path="/privacy-policy"
            render={(props) => <Privacy {...props} />}
            exact
          />
          <Route
            path="/term-of-use"
            render={(props) => <Term {...props} />}
            exact
          />
          <Route
            path="/*"
            render={(props) => <PageNotFound {...props} />}
            exact
          />
        </Switch>
      </Router>
    </div>
  );
}
