import React from "react";
import img from "../images/none.svg";

const NoData = ({ text }) => {
  return (
    <div className="row justify-content-end">
      <div className="col-md-8">
        <div className="no-data">
          <div className="row align-items-center">
            <div className="col-4">
              <img src={img} alt="No data" className="img-fluid" />
            </div>
            <div className="col-8">
              ไม่พบ <span>{text}</span> ในระบบ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoData;
