import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const MobileMenu = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="mobile-menu">
      <button
        className={classNames("bt-toggle-menu", open && "active")}
        onClick={() => setOpen(!open)}
      >
        <span></span>
      </button>
      <div className={classNames("menu-wrapper", open && "active")}>
        <ul>
          <li>
            <NavLink activeClassName="active" to="/" exact>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/winner-archives" activeClassName="active">
              Winner Archives
            </NavLink>
          </li>
          <li>
            <NavLink to="/leader-board-update" activeClassName="active" exact>
              Most Awarded Creative Leaderboard
            </NavLink>
          </li>
          <li>
            <a href="https://admanawards.com" target="_blank" rel="noreferrer">
              Adman Awards Website
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
