/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { breadcrumbActions } from "../actions/breadcrumb.action";
import { detailActions } from "../actions/detail.action";
import { searchActions } from "../actions/search.action";
import Filter from "../components/Filter";
import Layout from "../components/Layout";
import ListItem from "../components/ListItem";
import { youTubeGetID } from "../helpers";
import Slider from "react-slick";

const Detail = ({ match }) => {
  const year = match.params.year ? match.params.year : window.DEFAULT_YEAR;
  const category = match.params.category;
  const id = match.params.id;
  const detail = useSelector((state) => state.detail);
  const dispatch = useDispatch();
  const [display, setDisplay] = React.useState(1);

  React.useEffect(() => {
    dispatch(detailActions.fetch(id));
    dispatch(
      breadcrumbActions.addBreadcrumb({
        items: [
          {
            url: `/winner-archives/${year}`,
            name: "Winner Archives Adman " + year,
          },
          {
            url: `/winner-archives/${year}/${category}`,
            name: !detail.loading && detail.data.categories,
          },
          {
            url: `/winner-archives/${year}/${category}`,
            name: !detail.loading && detail.data.subcategories,
          },
        ],
        current: !detail.loading && detail.data.title,
      })
    );
  }, [dispatch, detail.loading]);

  React.useEffect(() => {
    dispatch(searchActions.resetSearchParams());
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <Layout breadcrumb>
      <Filter />
      {!detail.loading && (
        <div className="detail-wrapper">
          <div className="space-left">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="row justify-content-between">
                    <div className="col-8">
                      <h1>{detail.data.title}</h1>
                    </div>
                    <div className="col-4">
                      <div className="switch">
                        <div className="group">
                          <button
                            className={display === 1 ? "active" : ""}
                            onClick={() => setDisplay(1)}
                          >
                            1
                          </button>
                          {detail.data.video[0] && (
                            <button
                              className={display === 2 ? "active" : ""}
                              onClick={() => setDisplay(2)}
                            >
                              2
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  {display === 1 && (
                    <div className="">
                      <img
                        src={detail.data.image[0]}
                        alt={detail.data.title}
                        className="img-fluid"
                      />
                    </div>
                  )}
                  {display === 2 && (
                    <div className="">
                      <div className="ratio ratio-16x9">
                        <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${youTubeGetID(
                            detail.data.video[0]
                          )}`}
                          title={detail.data.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="row justify-content-center mt-5 mb-5">
                <div className="col-md-10">
                  <ListItem
                    label="ENTRY TITLE (ชื่องาน)"
                    value={detail.data.title}
                  />
                  <ListItem
                    label="CATEGORY (หมวด)"
                    value={detail.data.categories}
                  />
                  <ListItem
                    label="AWARD TYPE (รางวัล)"
                    value={detail.data.award_type}
                  />
                  <ListItem label="BRAND (แบรนด์)" value={detail.data.brand} />
                  <ListItem
                    label="ADVERTISER (ผู้ลงโฆษณา)"
                    value={detail.data.advertiser}
                  />
                  <ListItem
                    label="ADVERTISING AGENCY (บริษัทโฆษณา)"
                    value={detail.data.agency}
                  />
                  <ListItem
                    label="PRODUCTION HOUSE (โปรดักชั่นเฮ้าส์)"
                    value={detail.data.production_company}
                  />
                  <ListItem
                    label="PR AGENCY (ประชาสัมพันธ์)"
                    value={detail.data.pr_agency}
                  />
                  <ListItem
                    label="MEDIA AGENCY (สื่อ)"
                    value={detail.data.media_agency}
                  />
                  <ListItem
                    label="WORKING TEAM (คณะทีมทำงาน)"
                    value={detail.data.creative}
                  />
                </div>
              </div>

              {detail.data.international_award.length > 0 && (
                <div className="row justify-content-center mt-5 mb-5">
                  <div className="col-12">
                    <h2 className="sub-header">INTERNATIONAL AWARD(S) WON</h2>
                    <div className="overflow">
                      <table cellSpacing={3} cellPadding={10}>
                        <thead>
                          <tr>
                            <th>AWARD</th>
                            <th>YEAR</th>
                            <th>AWARD TYPE</th>
                            <th>CATEGORY</th>
                            <th>Sub - CATEGORY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detail.data.international_award.map((o, i) => (
                            <tr key={i}>
                              <td>{o.award}</td>
                              <td>{o.year}</td>
                              <td>{o.award_type}</td>
                              <td>{o.category}</td>
                              <td>{o.subcategory}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {detail.data.related.length > 0}
              <h3 className="related-entry-header">Related Entry</h3>
              <div className="slider-box">
                <div
                  className="slider"
                  //style={{ backgroundImage: `url(${o.image[0]})` }}
                >
                  <Slider {...settings}>
                    {detail.data.related.map((o, i) => (
                      <React.Fragment key={i}>
                        <div className="bg">
                          <a
                            href={`/winner-archives/${year}/${o.id_categories}/${o.id_subcategories}/${o.id}`}
                            className="image-hover"
                          >
                            <div
                              className="bg"
                              style={{ backgroundImage: `url(${o.image[0]})` }}
                              alt={o.title}
                            />
                            <div className="caption">{o.title}</div>
                          </a>
                        </div>
                      </React.Fragment>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Detail;
