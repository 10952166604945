import { BREADCRUMB } from "../types/breadcrumb.type";

const addBreadcrumb = (payload) => (dispatch) =>
  dispatch({
    type: BREADCRUMB.ADD,
    payload,
  });

const resetBreadcrumb = () => (dispatch) =>
  dispatch({
    type: BREADCRUMB.RESET,
  });

export const breadcrumbActions = {
  addBreadcrumb,
  resetBreadcrumb,
};
