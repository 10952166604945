import { SEARCH } from "../types/search.type";

const addSearchParams = (payload) => (dispatch) =>
  dispatch({
    type: SEARCH.ADD,
    payload,
  });

const resetSearchParams = () => (dispatch) =>
  dispatch({
    type: SEARCH.RESET,
  });

export const searchActions = {
  addSearchParams,
  resetSearchParams,
};
