import React from "react";
import { useSelector } from "react-redux";
import Error from "./Error";
import flagTH from "../images/th-flag.svg";

const LeaderBoardTable = () => {
  const { leaderBoard } = useSelector((state) => state);

  return (
    <>
      {!leaderBoard.loading && leaderBoard.error && (
        <Error messages={leaderBoard.error} />
      )}
      {!leaderBoard.loading && !leaderBoard.error && (
        <table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>RANK</th>
              <th>Name</th>
              <th>Agency</th>
              <th>Point</th>
            </tr>
          </thead>
          <tbody>
            {leaderBoard.data?.leaderboards.length === 0 && (
              <tr>
                <td colSpan={4} align="center">
                  No Data
                </td>
              </tr>
            )}
            {leaderBoard.data?.leaderboards && (
              <>
                {leaderBoard.data.leaderboards.map((o, i) => (
                  <tr key={i}>
                    <td>
                      {o.flag === "th" && <span className="flag-bg" />}
                      {o.rank}
                    </td>
                    <td>
                      {o.name}
                      {o.flag === "th" && (
                        <span
                          style={{ display: "inline-block", marginLeft: "4px" }}
                        >
                          <img src={flagTH} alt="th" />
                        </span>
                      )}
                    </td>
                    <td>{o.agency}</td>
                    <td>{o.point}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default LeaderBoardTable;
