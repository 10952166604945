import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import filterReducer from "./filter.reducer";
import breadcrumbReducer from "./breadcrumb.reducer";
import categoryReducer from "./category.reducer";
import subCategoryReducer from "./subCategory.reducer";
import singleSubCategory from "./singleSubCategory.reducer";
import detailReducer from "./detail.reducer";
import leaderBoardReducer from "./leaderBoard.reducer";
import searchReducer from "./search.reducer";

const store = createStore(
  combineReducers({
    filter: filterReducer,
    breadcrumb: breadcrumbReducer,
    leaderBoard: leaderBoardReducer,
    categories: categoryReducer,
    subCategories: subCategoryReducer,
    subCategory: singleSubCategory,
    detail: detailReducer,
    searchParams: searchReducer,
  }),
  applyMiddleware(thunk)
);
export default store;
