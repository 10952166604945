import { BREADCRUMB } from "../types/breadcrumb.type";

const initialState = {
  items: [],
  current: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BREADCRUMB.ADD:
      return {
        ...action.payload,
      };
    case BREADCRUMB.RESET:
      return { items: [], current: "" };
    default:
      return { ...state };
  }
};

export default reducer;
