import React from "react";
import { useSelector } from "react-redux";

const TextMode = ({ year, category }) => {
  const state = useSelector((state) => state.subCategories);
  return (
    <div className="container">
      <div className="text-mode">
        <div className="row">
          {!state.loading &&
            state.data?.subcategories &&
            state.data.subcategories.map((o, i) => (
              <div key={i}>
                <div className="col-12 mb-5">
                  <div className="space-left">
                    <h3>
                      <span className="code">{o.code}</span>
                      {o.title}
                    </h3>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          {o.entry.length > 0 &&
                            o.entry.map((e, j) => (
                              <div className="col-md-4" key={`${i}_${j}`}>
                                <a
                                  href={`/winner-archives/${year}/${category}/${o.id}/${e.id}`}
                                  className="entry-link"
                                >
                                  {e.title}
                                </a>
                                <p>
                                  <strong>AWARD : {e.award}</strong>
                                  <br />
                                  {e.brand}
                                  <br />
                                  {e.agency}
                                </p>
                              </div>
                            ))}
                          {o.entry.length === 0 && (
                            <div className="col">
                              <div className="no-entry">
                                NO AWARD <div className="line"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TextMode;
