import axios from "axios";
import { FILTERS } from "../types/filter.type";

const fetch = () => (dispatch) =>
  axios
    .get(window.API_URL + "/filters")
    .then(({ data }) => {
      const sortYears = data.data.years.sort(function (a, b) {
        return b.value - a.value;
      });

      dispatch({
        type: FILTERS.SUCCESS_FETCH,
        payload: { ...data.data, years: sortYears },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: FILTERS.FAIL_FETCH,
        payload: "Something went wrong",
      });
    });

export const filterAction = {
  fetch,
};
