import axios from "axios";
import { SINGLE_SUB_CATEGORY } from "../types/singleSubCategory.type";

const fetch = (id) => (dispatch) =>
  axios
    .get(`${window.API_URL}/archives/subcategories/${id}`)
    .then(({ data }) => {
      dispatch({
        type: SINGLE_SUB_CATEGORY.SUCCESS_FETCH,
        payload: data.data,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: SINGLE_SUB_CATEGORY.FAIL_FETCH,
        payload: "Something went wrong",
      });
    });

export const singleSubCategoryActions = {
  fetch,
};
