import { useSelector } from "react-redux";

export default function Breadcrumb() {
  const state = useSelector((state) => state.breadcrumb);

  return (
    <div className="breadcrumb-ui">
      <div className="container">
        <div className="row">
          <div className="col">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              {state.items.map((o, i) => (
                <li key={i}>
                  <a href={o.url}>{o.name}</a>
                </li>
              ))}
              <li>
                <span>{state.current}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
