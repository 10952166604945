import React from "react";
import Layout from "../components/Layout";

const Term = () => {
  return (
    <Layout>
      <div className="container term">
        <div className="row justify-content-center py-4">
          <div className="col-md-8">
            <h1>
              Term of Use
              <span>ข้อกำหนดและนโยบายการให้บริการเว็บไซต์</span>
            </h1>
            <h2 className="text-center">คำนิยาม</h2>
            <p className="text-center">
              “สมาคมโฆษณาฯ” หรือ “ผู้ให้บริการ” หมายความถึง
              สมาคมโฆษณาแห่งประเทศไทย <br /> “ผู้ใช้บริการ” หมายความถึง
              บุคคลธรรมดาหรือนิติบุคคลที่ใช้บริการใดๆ ของ สมาคมโฆษณาฯ <br />
              “บริการ” หมายความรวมถึง การใช้ผลิตภัณฑ์ ซอฟต์แวร์ และเว็บไซต์ของ
              สมาคมโฆษณาฯ
            </p>
            <h4>การใช้ข้อมูลส่วนบุคคล</h4>
            <p>
              สมาคมโฆษณาฯ จะใช้ข้อมูลส่วนบุคคลของท่านเพียงเท่าที่จำเป็น เช่น
              อีเมลล์ เพื่อใช้ในการติดต่อให้บริการ ประชาสัมพันธ์
              หรือให้ข้อมูลข่าวสารต่างๆ
              รวมทั้งสำรวจความคิดเห็นของท่านในกิจการหรือกิจกรรมของ สมาคมโฆษณาฯ
              เท่านั้น
            </p>
            <p>
              สมาคมโฆษณาฯ ขอรับรองว่าจะไม่นำข้อมูลส่วนบุคคลของท่านที่
              สมาคมโฆษณาฯ ได้เก็บรวบรวมไว้ไปจำหน่าย
              หรือเผยแพร่ให้กับบุคคลภายนอกโดยเด็ดขาด
              เว้นแต่จะได้รับอนุญาตจากท่านเท่านั้น
            </p>
            <p>
              ในกรณี สมาคมโฆษณาฯ ได้ว่าจ้างหน่วยงานอื่น
              เพื่อให้ดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่าน เช่น
              การจัดส่งพัสดุไปรษณีย์
              การวิเคราะห์เชิงสถิติในกิจการหรือกิจกรรมของสมาคมโฆษณาฯ เป็นต้น
              สมาคมโฆษณาฯ
              จะกำหนดให้หน่วยงานที่ได้ว่าจ้างให้ดำเนินการดังกล่าวเก็บรักษาความลับและความปลอดภัยของข้อมูลส่วนบุคคลของท่าน
              และกำหนดข้อห้ามมิให้มีการนำข้อมูลส่วนบุคคลดังกล่าวไปใช้นอกเหนือจากกิจกรรมหรือกิจการของ
              สมาคมโฆษณาฯ
            </p>
            <h4>วัตถุประสงค์</h4>
            <p>
              เว็บไซต์ Adman Archive
              ได้จัดทำขึ้นเพื่อให้บริการในการใช้บริการข้อมูล ข่าวสาร
              แก่ประชาชนทั่วไป
              การใช้บริการเว็บไซต์ของผู้ใช้บริการจะอยู่ภายใต้เงื่อนไขและข้อกำหนด
            </p>
            <p>
              ผู้ใช้บริการจึงควรศึกษาเงื่อนไขและข้อกำหนด การใช้งานเว็บไซต์
              และ/หรือเงื่อนไข และข้อตกลงอื่นใด ที่ สมาคมโฆษณาฯ
              ได้แจ้งให้ทราบบนเว็บไซต์โดยละเอียดก่อนการเข้าใช้บริการ
              ทั้งนี้ในการใช้บริการ
              ให้ถือว่าผู้ใช้บริการได้ตกลงที่จะปฏิบัติตามเงื่อนไขและข้อกำหนดการให้บริการที่กำหนดไว้นี้
              หากผู้ใช้บริการไม่ประสงค์ที่จะผูกพันตามข้อกำหนด
              และเงื่อนไขการให้บริการท่านสามารถยุติการเข้าชมและใช้งานเว็บไซต์นี้ในทันที
            </p>
            <h4>ข้อกำหนดในการให้บริการของ สมาคมโฆษณาฯ</h4>
            <ol>
              <li>
                <strong>ข้อกำหนด</strong>
                <ol>
                  <li>
                    สมาคมโฆษณาฯ
                    จะให้บริการแก่ผู้ใช้บริการภายใต้ข้อกำหนดและนโยบายการให้บริการ
                    ที่กำหนดไว้ในเอกสารฉบับนี้
                  </li>
                  <li>
                    “ข้อกำหนด” ถือเป็นสาระสำคัญในการให้บริการของ สมาคมโฆษณาฯ
                    โดยผู้ใช้บริการมีหน้าที่จะต้องอ่านข้อกำหนดเหล่านี้ให้เข้าใจโดยละเอียด
                    และให้มีผลผูกพันทางกฎหมายระหว่างผู้ใช้บริการและ สมาคมโฆษณาฯ
                  </li>
                  <li>
                    สมาคมโฆษณาฯ คงไว้ซึ่งสิทธิในการแก้ไขเพิ่มเติมข้อกำหนด
                    โดยไม่ต้องแจ้งให้แก่ผู้ใช้บริการทราบล่วงหน้าเป็นลายลักษณ์อักษร
                  </li>
                </ol>
              </li>
              <li>
                <strong>การยอมรับข้อกำหนด</strong>
                <ol>
                  <li>
                    ผู้ใช้บริการจะต้องรับทราบและยอมรับข้อกำหนด มิฉะนั้น
                    ผู้ใช้บริการจะไม่สามารถใช้บริการได้
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถรับทราบและยอมรับข้อกำหนดได้โดยการใช้บริการของ
                    สมาคมโฆษณาฯ ในกรณีนี้
                    ให้ถือว่าผู้ใช้บริการรับทราบและยอมรับข้อกำหนดโดยปริยายนับจากเวลาที่เริ่มใช้บริการเป็นต้นไป
                  </li>
                  <li>
                    ผู้ใช้บริการควรบันทึกหรือทำสำเนาข้อกำหนดทั่วไปไว้เพื่อเป็นหลักฐาน
                  </li>
                </ol>
                <li>
                  <strong>การให้บริการโดย สมาคมโฆษณาฯ</strong>
                  <ol>
                    <li>
                      สมาคมโฆษณาฯ
                      อาจมีการพัฒนาหรือปรับปรุงรูปแบบและลักษณะของบริการเพื่อมอบประสบการณ์ที่ดีที่สุดเท่าที่จะเป็นไปได้แก่ผู้ใช้บริการ
                      โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                    </li>
                    <li>
                      สมาคมโฆษณาฯ อาจหยุดให้บริการเป็นการชั่วคราวหรือถาวร
                      หรือยกเลิกการให้บริการแก่ผู้ใช้บริการรายใดเป็นการเฉพาะ
                      หากการให้บริการดังกล่าวส่งผลกระทบต่อผู้ใช้บริการอื่นๆ
                      หรือขัดแย้งต่อข้อกฏหมาย
                      โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                    </li>
                    <li>
                      การหยุด หรือการยกเลิกบริการตามข้อ 3.2
                      ผู้ใช้บริการจะไม่สามารถเข้าใช้บริการ
                      และเข้าถึงรายละเอียดบัญชีของผู้ใช้บริการ ไฟล์เอกสารใดๆ
                      หรือเนื้อหาอื่นๆ ที่อยู่ในบัญชีของผู้ใช้บริการได้
                      หรือในกรณีที่ สมาคมโฆษณาฯ หยุดให้บริการเป็นการถาวร
                      หรือยกเลิกบริการแก่ผู้ใช้บริการ สมาคมโฆษณาฯ
                      มีสิทธิในการลบข้อมูลต่างๆ ที่อยู่ในบัญชีของผู้ใช้บริการได้
                      โดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้า
                    </li>
                  </ol>
                </li>
              </li>
              <li>
                <strong>หน้าที่ของผู้ใช้บริการ</strong>
                <ol>
                  <li>
                    ผู้ใช้บริการต้องใช้บริการนี้ตามข้อตกลงที่ได้ทำกับ
                    สมาคมโฆษณาฯ และไม่ขัดต่อข้อกฎหมาย กฎ ระเบียบ ข้อบังคับของ
                    สมาคมโฆษณาฯ และหลักปฏิบัติที่เป็นที่ยอมรับโดยทั่วไป
                  </li>
                  <li>
                    ผู้ใช้บริการจะไม่เข้าใช้
                    หรือพยายามเข้าใช้บริการหนึ่งบริการใดโดยวิธีอื่น
                    รวมถึงการใช้วิธีการอัตโนมัติ (การใช้สคริปต์)
                    นอกจากช่องทางที่ สศส จัดเตรียมไว้ให้
                    เว้นแต่ผู้ใช้บริการจะได้รับอนุญาตจาก
                    สมาคมโฆษณาฯโดยชัดแจ้งให้ทำเช่นนั้นได้
                  </li>
                  <li>
                    ผู้ใช้บริการจะไม่กระทำหรือมีส่วนร่วมในการขัดขวางหรือรบกวนบริการของ
                    สมาคมโฆษณาฯ
                    รวมทั้งเครื่องแม่ข่ายและเครือข่ายที่เชื่อมต่อกับบริการ
                  </li>
                  <li>
                    ผู้ใช้บริการจะไม่ทำสำเนา คัดลอก ทำซ้ำ ขาย แลกเปลี่ยน
                    หรือขายต่อบริการเพื่อวัตถุประสงค์ใดๆ
                    เว้นแต่ผู้ใช้บริการจะได้รับอนุญาตจาก สมาคมโฆษณาฯ
                    โดยชัดแจ้งให้ทำเช่นนั้นได้
                  </li>
                  <li>
                    ผู้ใช้บริการจะเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อบุคคลใดๆ
                    ในความเสียหายอันเกิดจากการละเมิดข้อกำหนด
                  </li>
                </ol>
              </li>
              <li>
                <strong>
                  การรักษาความเป็นส่วนตัวและข้อมูลส่วนบุคคลของผู้ใช้บริการ
                </strong>
                <ol>
                  <li>
                    สมาคมโฆษณาฯ
                    จะปฏิบัติต่อข้อมูลส่วนบุคคลและปกป้องความเป็นส่วนตัวของผู้ใช้บริการ
                    ภายใต้นโยบายส่วนบุคคลของ สมาคมโฆษณาฯ
                  </li>
                  <li>
                    ผู้ใช้บริการตกลงว่าจะปฏิบัติตามนโยบายส่วนบุคคลของ
                    สมาคมโฆษณาฯ
                  </li>
                </ol>
              </li>
              <li>
                <strong>เนื้อหาในบริการ</strong>
                <ol>
                  <li>
                    ผู้ใช้บริการอาจได้รับ เข้าถึง สร้าง ส่ง หรือแสดงข้อมูล เช่น
                    ไฟล์ข้อมูล ข้อความลายลักษณ์อักษร ซอฟต์แวร์คอมพิวเตอร์ ดนตรี
                    ไฟล์เสียง หรือเสียงอื่นๆ ภาพถ่าย วิดีโอ หรือรูปภาพอื่นๆ
                    โดยเป็นส่วนหนึ่งของบริการหรือโดยผ่านการใช้บริการ
                    ซึ่งต่อไปนี้จะเรียกว่า “เนื้อหา”
                  </li>
                  <li>
                    เนื้อหาที่นำเสนอต่อผู้ใช้บริการ
                    อาจได้รับการคุ้มครองโดยสิทธิในทรัพย์สินทางปัญญาของเจ้าของเนื้อหานั้น
                    ผู้ใช้บริการไม่มีสิทธิเปลี่ยนแปลงแก้ไขจำหน่ายจ่ายโอน
                    หรือสร้างผลงานต่อเนื่องโดยอาศัยเนื้อหาดังกล่าวไม่ว่าจะทั้งหมดหรือบางส่วน
                    เว้นแต่ผู้ใช้บริการจะได้รับอนุญาตโดยชัดแจ้งจากเจ้าของเนื้อหานั้น
                  </li>
                  <li>
                    สมาคมโฆษณาฯ ทรงไว้ซึ่งสิทธิในการคัดกรอง ตรวจทาน
                    ทำเครื่องหมาย เปลี่ยนแปลงแก้ไข ปฏิเสธ หรือลบเนื้อหาใดๆ
                    ที่ไม่เหมาะสมออกจากบริการ ซึ่ง สมาคมโฆษณาฯ
                    อาจจัดเตรียมเครื่องมือในการคัดกรองเนื้อหาอย่างชัดเจนโดยไม่ขัดต่อกฎหมาย
                    กฎ ระเบียบของทางราชการที่เกี่ยวข้อง
                  </li>
                  <li>
                    สมาคมโฆษณาฯ จะไม่มีส่วนรับผิดชอบต่อบุคคลใดๆ
                    ในความสูญเสียหรือเสียหาย อันเกิดจากเนื้อหาที่ สมาคมโฆษณาฯ
                    มิได้เป็นผู้สร้างขึ้น หากผู้ใช้บริการได้สร้าง ส่ง
                    หรือแสดงเนื้อหาใดๆ ในบริการ
                    ผู้ใช้บริการจะต้องเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อความสูญเสียหรือเสียหายใดๆ
                    อันเกิดจากเนื้อหาดังกล่าวนั้นต่อบุคคลใดๆ รวมถึง สมาคมโฆษณาฯ
                    ด้วย
                  </li>
                </ol>
              </li>
              <li>
                <strong>กรรมสิทธิ์และสิทธิในทรัพย์สินทางปัญญา</strong>
                <ol>
                  <li>
                    สมาคมโฆษณาฯ หรือผู้ให้อนุญาตแก่ สมาคมโฆษณาฯ
                    เป็นผู้มีสิทธิตามกฎหมายแต่เพียงผู้เดียวในกรรมสิทธิ์
                    ผลประโยชน์ทั้งหมดรวมถึงสิทธิในทรัพย์สินทางปัญญาใดๆ
                    ที่มีอยู่ในบริการซึ่ง สมาคมโฆษณาฯ หรือผู้ให้อนุญาตแก่
                    สมาคมโฆษณาฯ เป็นผู้จัดทำขึ้น
                    ไม่ว่าสิทธิเหล่านั้นจะได้รับการจดทะเบียนไว้หรือไม่ก็ตาม
                  </li>
                  <li>
                    ผู้ใช้บริการจะต้องไม่เปิดเผยข้อมูลที่ สมาคมโฆษณาฯ
                    กำหนดให้เป็นความลับ หรือนำไปใช้ทางการค้า
                    หรือแสวงหาผลประโยชน์
                    โดยไม่ได้รับความยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก
                    สมาคมโฆษณาฯ
                  </li>
                  <li>
                    ผู้ใช้บริการจะต้องไม่ใช้ชื่อทางการค้า เครื่องหมายการค้า
                    เครื่องหมายการบริการ ตราสัญลักษณ์ ชื่อโดเมนของ สมาคมโฆษณาฯ
                    โดยไม่ได้รับความยินยอม เป็นลายลักษณ์อักษรจาก สมาคมโฆษณาฯ
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Term;
