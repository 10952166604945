import React from "react";

const Error = ({ messages }) => {
  return (
    <div className="error-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card mt-5">
              <div className="card-header">ERROR</div>
              <div className="card-body">
                <div className="text-center text-danger">
                  <h3 className="mt-3">{messages}</h3>
                </div>
                <div className="text-center mt-5 mb-3">
                  <a href="/" className="btn btn-black">
                    Back To Homepage
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
