import axios from "axios";
import { DETAIL } from "../types/detail.type";

const fetch = (id) => (dispatch) =>
  axios
    .get(window.API_URL + "/archive/" + id)
    .then(({ data }) => {
      dispatch({
        type: DETAIL.SUCCESS_FETCH,
        payload: data.data,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: DETAIL.FAIL_FETCH,
        payload: "Something went wrong",
      });
    });

export const detailActions = {
  fetch,
};
