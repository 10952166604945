import React from "react";
import Layout from "../components/Layout";

const Privacy = () => {
  return (
    <Layout>
      <div className="container privacy">
        <div className="row py-4 justify-content-center">
          <div className="col-md-8">
            <h1>
              Adman Archive Privacy Policy{" "}
              <span>นโยบายการจัดเก็บ ใช้ เปิดเผย และจัดการข้อมูลส่วนบุคคล</span>
            </h1>

            <p>
              สมาคมโฆษณาแห่งประเทศไทย (ซึ่งต่อไปนี้จะเรียกว่า ‘สมาคมโฆษณาฯ’)
              ตระหนักถึงความสำคัญของนโยบายพิทักษ์สิทธิส่วนบุคคลเป็นอย่างยิ่ง
              สมาคมโฆษณาฯขอยืนยันการเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นความลับอย่างดีที่สุด
              กรุณาอ่านรายละเอียดของนโยบายพิทักษ์สิทธิส่วนบุคคลนี้และกรุณาทำความเข้าใจเกี่ยวกับการดำเนินการที่เกี่ยวข้องพร้อมทั้งข้อมูลส่วนบุคคลของท่าน
            </p>

            <ol>
              <li>
                <strong>การจัดเก็บข้อมูลส่วนบุคคล</strong>
                <ol>
                  <li>
                    การจัดเก็บข้อมูลส่วนบุคคลแบบทางตรง <br />
                    สมาคมโฆษณาฯ จะเก็บข้อมูลส่วนบุคคลของท่าน
                    เมื่อท่านลงทะเบียนร่วมกิจกรรมต่าง ๆ ของสมาคมโฆษณาฯ
                    และยินยอมให้สมาคมโฆษณาฯ เก็บ ใช้และเปิดเผยข้อมูลส่วนบุคคล
                    ซึ่งการเปิดเผยข้อมูลส่วนบุคคล
                    จะเป็นประโยชน์อย่างยิ่งต่อการนำเสนอบริการต่าง ๆ
                    ที่ตรงกับความต้องการและความสนใจของท่าน
                    ตัวอย่างของข้อมูลที่จะจัดเก็บตามความสมัครใจของท่านแต่ไม่จำกัดเพียง
                    คือ อีเมล ชื่อ และข้อมูลติดต่อ ละความสนใจของท่าน
                  </li>
                  <li>
                    ข้อกำหนดการใช้ cookies
                    <br />
                    Cookies คือ
                    ข้อมูลขนาดเล็กที่จะถูกส่งไปเก็บไว้ในบราวเซอร์ของท่าน
                    เพื่อทำการเก็บข้อมูลการเข้าเยี่ยมชม
                    เมื่อท่านเปิดเข้าไปเยี่ยมชมเว็บไซต์ของสมาคมโฆษณาฯ อีกครั้ง
                    ในคราวหลังเครื่องก็จะจำได้ทันทีว่าท่านเคยเข้ามาเยี่ยมชมแล้ว
                    Cookies
                    ไม่ใช่โปรแกรมที่จะเข้าไปอยู่ในระบบคอมพิวเตอร์ของผู้ใช้งานแล้วทำลายไฟล์ต่าง
                    ๆ แต่มีไว้เพื่อช่วยให้สมาคมโฆษณาฯ
                    คอยติดตามว่าผู้ใช้แวะเยี่ยมหน้าใดบ้าง
                    เริ่มต้นจากหน้าไหนจบลงด้วยหน้าไหนและบ่อยแค่ไหน สมาคมโฆษณาฯ
                    ไม่ได้ใช้ Cookies เพื่อตรวจสอบว่าผู้ใช้คือใคร
                    เพียงแต่บอกว่าเครื่องคอมพิวเตอร์นี้เคยเข้ามาเยี่ยม
                    ชมเว็บไซต์ของสมาคมโฆษณาฯ แล้ว Cookies
                    ไม่ได้ใช้สำหรับเก็บข้อมูลส่วนบุคคล เช่น ชื่อ ที่อยู่ อีเมล
                    หากท่านไม่ประสงค์ให้ Cookies เข้าไปอยู่ในคอมพิวเตอร์ของท่าน
                    ท่านสามารถสั่งให้บราวเซอร์ปฏิเสธไฟล์ Cookies ได้
                  </li>
                  <li>
                    ข้อกำหนดการใช้ Web Beacons <br />
                    สมาคมโฆษณาฯ อาจมีการใช้เทคโนโลยีที่เรียกว่า “web beacons”
                    หรือ “pixel tags” ร่วมกับ cookies
                    เพื่อตรวจสอบว่าท่านได้เข้าถึงข้อมูลที่สมาคมโฆษณาฯ
                    เสนอทางเว็บไซต์หรืออีเมลแล้วหรือไม่เพื่อจะได้นำเสนอข้อมูลบริการให้ตรงความต้องการแต่ละท่านมากที่สุด
                    โดยปกติท่านจะสามารถลดความสามารถของ web beacons
                    ได้โดยสั่งให้บราวเซอร์ปฏิเสธไฟล์ Cookies ได้
                  </li>
                  <li>
                    การเปิดเผยข้อมูลกับบุคคลภายนอกผู้ซึ่งให้บริการ <br />
                    สามารถตรวจสอบรายละเอียดได้ในข้อ 3.
                  </li>
                </ol>
              </li>
              <li>
                <strong>การใช้ข้อมูลส่วนบุคคล</strong>
                <br />
                จุดประสงค์หลักในการจัดเก็บข้อมูลส่วนบุคคล คือ
                นำเสนอบริการที่เหมาะสมที่สุดแก่ท่านในช่วงเวลาที่ท่านเข้ามาแวะเยี่ยมชม
                และใช้บริการเว็บไซต์ โดยไม่เสีย ค่าใช้จ่ายใด ๆ ทั้งสิ้น
                ซึ่งในบางครั้งอาจมีจดหมายอิเล็คทรอนิกส์ส่งถึงท่าน
                เพื่อแจ้งข้อมูลข่าวสาร กิจกรรม และบริการต่าง ๆ
                ทั้งนี้หากท่านไม่สนใจหรือไม่มี
                ความประสงค์ในการรับข้อมูลข่าวสารต่าง ๆ
                ท่านสามารถแจ้งยกเลิกได้ทุกเมื่อ
                สำหรับข้อมูลทุกชนิดที่ท่านมอบให้สมาคมโฆษณาฯ ท่านมอบสิทธิในการใช้
                ทำสำเนา แก้ไข ลบทิ้งทั้งหมด ดัดแปลง เผยแพร่ แปล
                และนำไปสร้างสรรค์งาน และ/หรือ
                นำข้อมูลดังกล่าวเข้าประกอบกับสื่อหรือเทคโนโลยีทุกรูปแบบที่มีอยู่โดยไม่จำต้องจ่ายค่าตอบแทนใด
                ๆ แก่ท่าน <br /> นอกจากนั้นสมาคมโฆษณาฯ
                รวบรวมข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ดังกล่าวนี้
                รวมถึงแต่ไม่จำกัดเพียง
                <ol>
                  <li>การพัฒนาบริการและการใช้งานเว็บไซต์ของท่าน</li>
                  <li>
                    ปรับแต่งเว็บไซต์ตามความต้องการ
                    รวมถึงการประเมินสถิติการเข้าเว็บไซต์
                  </li>
                  <li>
                    ปรับปรุงให้เว็บไซต์ใช้งานได้งานขึ้นเหมาะสมกับความสนใจและความต้องการของท่าน
                  </li>
                  <li>ทำการสื่อสารกับท่านเกี่ยวกับบริการของสมาคมโฆษณาฯ</li>
                  <li>ส่งข้อมูลเพิ่มตามที่ท่านร้องขอ</li>
                  <li>ตอบข้อเสนอแนะของท่าน</li>
                  <li>แนะนำบริการที่สมาคมโฆษณาฯเห็นว่าท่านอาจมีความสนใจ</li>
                </ol>
              </li>
              <li>
                <strong>การเปิดเผยข้อมูลส่วนบุคคล</strong>
                <br />
                ปกติแล้วสมาคมโฆษณาฯ มิได้เปิดเผยข้อมูลส่วนบุคคลของท่านต่อผู้อื่น
                เว้นแต่ในกรณีที่ได้รับอนุญาตจากท่านและในกรณีพิเศษ ดังนี้
                <ol>
                  <li>
                    ตัวแทนสื่อโฆษณา การตลาด
                    หรือการส่งเสริมการขายที่ช่วยเพิ่มและวิเคราะห์ประสิทธิภาพของสื่อโฆษณา
                    การตลาด และการส่งเสริมการขาย
                  </li>
                  <li>
                    ผู้ใช้บังคับกฎหมาย
                    หรือหน่วยงานราชการที่อำนาจร้องขอให้เปิดเผยข้อมูล
                  </li>
                  <li>
                    บุคคลภายนอกผู้ซึ่งให้บริการ เช่น
                    บริการดำเนินการเกี่ยวกับข้อมูล หรือผู้วิเคราะห์เว็บไซต์ เช่น
                    Google Analytics
                  </li>
                  <li>ตามเงื่อนไขในหัวข้อ 2. การใช้ข้อมูลส่วนบุคคล</li>
                  <li>ตรวจสอบเพื่อระบุตัวตนของท่าน</li>
                  <li>เพื่อการบังคับใช้ข้อกำหนดและเงื่อนไขนี้ของเว็บไซต์</li>
                  <li>เพื่อการตรวจสอบความเป็นไปได้ในการกระทำผิดกฎหมาย</li>
                  <li>
                    เพื่อทำให้เป็นไปตามคำสั่งศาลหรือกระบวนการทางกฎหมายอื่นภายในเขตอำนาจศาลที่สมาคมโฆษณาฯ
                    ดำเนินงานอยู่
                  </li>
                </ol>
              </li>
              <li>
                <strong>เว็บไซต์และบริการของบุคคลภายนอกผู้ซึ่งให้บริการ</strong>
                <br />
                เว็บไซต์ของสมาคมโฆษณาฯ อาจจะมีลิงค์ (link)
                ไปที่เว็บไซต์และบริการของบุคคลภายนอกผู้ซึ่งให้บริการ
                หากท่านกดลิงค์ดังกล่าวไปที่เว็บไซต์และบริการของบุคคลภายนอกผู้ซึ่งให้บริการ
                ท่านอาจถูกเก็บข้อมูลส่วนบุคคลของท่าน
                ซึ่งทางสมาคมโฆษณาฯไม่สามารถรับผิดชอบใด ๆ
                แทนบุคคลภายนอกผู้ซึ่งให้บริการได้
                สมาคมโฆษณาฯแนะนำให้ท่านตรวจสอบนโยบายการจัดเก็บ ใช้ เปิดเผย
                และจัดการข้อมูลส่วนบุคคลของผู้ให้บริการนั่น ๆ ต่อไป
              </li>
              <li>
                <strong>ความปลอดภัยและการจัดเก็บข้อมูลส่วนบุคคล</strong>
                <br />
                ข้อมูลที่จัดเก็บทั้งหมดจะถูกจัดเก็บอย่างปลอดภัยและป้องกันข้อมูลส่วนบุคคลของท่านโดย
                <ul>
                  <li>จำกัดการเข้าถึงข้อมูลส่วนบุคคล</li>
                  <li>
                    จัดให้มีวิธีการเพื่อป้องกันไม่ให้มีการเข้าสู่ระบบคอมพิวเตอร์ที่ไม่ได้รับอนุญาต
                  </li>
                  <li>
                    จัดการทำลายข้อมูลส่วนบุคคลของท่านเพื่อความปลอดภัยเมื่อข้อมูลดังกล่าวไม่จำเป็นสำหรับวัตถุประสงค์ทางกฎหมายและธุรกิจอีกต่อไป
                  </li>
                </ul>
                สมาคมโฆษณาฯจะจัดเก็บข้อมูลส่วนบุคคลของท่านตามระยะเวลาที่จำเป็นไปจนกว่าท่านจะแจ้งขอลบข้อมูลส่วนบุคคลที่ให้ไว้กับสมาคมโฆษณาฯ
                หรือเพื่อทำให้เป็นไปตามคำสั่งศาลหรือกระบวนการทางกฎหมาย
              </li>

              <li>
                <strong>
                  สิทธิในการแก้ไขข้อมูลส่วนบุคคลของท่านและการยกเลิกความยินยอมการใช้และเปิดเผยข้อมูลส่วนบุคคล
                </strong>
                <br />
                ท่านมีสิทธิที่จะขอเข้าถึง เปลี่ยนแปลง และ
                ขอแจ้งลบข้อมูลส่วนบุคคลที่ให้ไว้กับสมาคมโฆษณาฯ โดยการ
                "ติดต่อสมาคมโฆษณาฯ" ซึ่งรายละเอียดและขั้นตอนการดำเนินการ
                ท่านสามารถตรวจสอบได้จากข้อมูล "ติดต่อสมาคมโฆษณาฯ"
                ที่ระบุไว้ในนโยบายการจัดเก็บ ใช้ เปิดเผย
                และจัดการข้อมูลส่วนบุคคลนี้
              </li>
              <li>
                <strong>สิทธิในการแก้ไขข้อกำหนดและเงื่อนไข</strong>
                <br />
                สมาคมโฆษณาฯ สงวนสิทธิที่จะแก้ไขข้อกำหนดและเงื่อนไขนี้ได้ตลอดเวลา
                กรณีที่มีการเปลี่ยนแปลงในสาระสำคัญ
                การเปลี่ยนแปลงนโยบายดังกล่าวจะถูกประกาศพร้อมวันที่กำกับไว้ในเว็บไซต์นี้
                สมาคมโฆษณาฯแนะนำให้ท่านตรวจสอบข้อกำหนดและเงื่อนไขอย่างสม่ำเสมอเพื่อทราบถึงความเปลี่ยนแปลงใด
                ๆ ที่เกิดขึ้น
              </li>
              <li>
                <strong>สิทธิของสมาคมโฆษณาฯ</strong> <br />
                ท่านรับทราบและตกลงว่า สมาคมโฆษณาฯ
                มีสิทธิในการเปิดเผยข้อมูลส่วนบุคคลแก่
                หน่วยงานผู้มีอำนาจทางกฎหมาย หน่วยงานการกำกับควบคุมดูแล
                หน่วยงานของรัฐ หน่วยงานเกี่ยวกับภาษี
                หน่วยงานในการบังคับใช้กฎหมาย และหน่วยงานอื่น ๆ ที่เกี่ยวข้อง
                หรือเจ้าของสิทธิต่าง ๆ ที่เกี่ยวข้อง หากสมาคมโฆษณาฯ
                มีเหตุอันควรเชื่อได้ว่าการเปิดเผยข้อมูลส่วนบุคคลของท่านนั้นจำเป็นสำหรับการปฏิบัติตามหน้าที่
                ความรับผิดขอบ การจัดการและข้อตกลง ไม่ว่าโดยสมัครใจหรือการบังคับ
                เพื่อวัตถุประสงค์ในการให้ความร่วมมือกับคำสั่ง การสอบสวน
                และ/หรือการร้องขอในรูปแบบต่าง ๆ ของหน่วยงานนั้น ๆ
                ภายใต้การบังคับของกฎหมายที่เกี่ยวข้อง
                ท่านตกลงว่าจะไม่ฟ้องร้องดำเนินคดีหรือดำเนินการใด ๆ
                ต่อสมาคมโฆษณาฯ
                และสละสิทธิเรียกร้องหรือสิทธิอื่นใดอันมีต่อสมาคมโฆษณาฯ
                ที่อาจเกิดขึ้นจากการเปิดเผยข้อมูลส่วนบุคคลของท่านภายใต้สถานการณ์ดังกล่าวข้างต้น
              </li>
              <li>
                <strong>ข้อมูลที่ท่านมอบให้กับทางสมาคมโฆษณาฯ</strong>
                <br />
                ในการระบุข้อมูลใด ๆ บนหรือผ่านเว็บไซต์สมาคมโฆษณาฯ และ/หรือ
                บริษัทในเครือ หรือผู้ให้บริการ ท่านมีความรับผิดชอบดังต่อไปนี้
                <ol>
                  <li>ท่านเป็นผู้มีกรรมสิทธิ์ในข้อมูลนั้น</li>
                  <li>ข้อมูลนั้นเป็นความจริงและถูกต้อง</li>
                  <li>
                    ข้อมูลนั้นจะไม่ก่อให้เกิดความเสียหายต่อบุคคลหรือนิติบุคคลใด
                  </li>
                  <li>
                    ข้อมูลนั้นมิใช่ข้อมูลที่ได้มาโดยการละเมิดลิขสิทธิ์
                    เครื่องหมายการค้า กรรมสิทธิ์
                    หรือสิทธิ์ในการเผยแพร่และความเป็นส่วนตัว
                  </li>
                  <li>
                    ข้อมูลนั้นไม่ละเมิดบทบัญญัติแห่งกฎหมาย กฎ ระเบียบ
                    หรือข้อบังคับใด ๆ
                  </li>
                  <li>
                    ข้อมูลนั้นต้องไม่มีลักษณะสบประมาท กล่าวร้าย ดูถูก เกลียดชัง
                    หรือก่อให้เกิดความขัดแย้งทางศาสนา
                    หรือคุกคามบุคคลหนึ่งบุคคลใด
                  </li>
                  <li>
                    ข้อมูลนั้นต้องไม่อ้างถึงเว็บไซต์ ที่อยู่อีเมล ที่อยู่ติดต่อ
                    หรือหมายเลขโทรศัพท์ของเว็บไซต์หรือบุคคลอื่นใดความยินยอมจากบุคคลดังกล่าวในการระบุความคล้ายคลึง/ชื่อของบุคคลดังกล่าวในข้อมูล
                  </li>
                  <li>
                    หากข้อมูลนั้นมีความคล้ายคลึงหรือมีการระบุชื่อของบุคคลผู้มีตัวตน
                    ท่านต้องได้รับความยินยอมจากบุคคลดังกล่าวในการระบุความคล้ายคลึง/ชื่อของบุคคลดังกล่าวในข้อมูล
                  </li>
                  <li>
                    ข้อมูลนั้นต้องไม่มีไวรัสคอมพิวเตอร์
                    ตลอดจนโปรแกรมหรือไฟล์อย่างหนึ่งอย่างใดที่มีลักษณะบ่อนทำลายและอาจก่อให้เกิดความเสียหาย
                  </li>
                </ol>
              </li>

              <li>
                <strong>ติดต่อสมาคมโฆษณาฯ</strong>
                <br />
                ท่านมีสิทธิที่จะขอเข้าถึง เปลี่ยนแปลง และ
                ขอแจ้งลบข้อมูลส่วนบุคคลที่ให้ไว้กับสมาคมโฆษณาฯโดยติดต่อ
                สมาคมโฆษณาแห่งประเทศไทย ได้ที่อีเมล{" "}
                <a href="mailto:contact@adassothai.com">
                  contact@adassothai.com
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
