import React from "react";

const ListItem = ({ label, value }) => {
  return (
    <div className="row list-item">
      <div className="col-7 col-md-6 list-item-label mb-1">
        {label}
        <span>:</span>
      </div>
      <div className="col-5 col-md-6">{!value ? "-" : value}</div>
    </div>
  );
};

export default ListItem;
