import axios from "axios";
import { CATEGORY } from "../types/category.type";

const fetch = (year) => (dispatch) =>
  axios
    .get(window.API_URL + "/archives/categories?year=" + year)
    .then(({ data }) => {
      dispatch({
        type: CATEGORY.SUCCESS_FETCH,
        payload: data.data,
      });
    })
    .catch((error) => {
      // console.error(error);
      dispatch({
        type: CATEGORY.FAIL_FETCH,
        payload: "Something went wrong",
      });
    });

const fetchSearch = (dispatch, params) => {
  axios
    .get(window.API_URL + "/archives/search" + params)
    .then(({ data }) => {
      dispatch({
        type: CATEGORY.SUCCESS_FETCH,
        payload: data.data,
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: CATEGORY.FAIL_FETCH,
        payload: "Something went wrong",
      });
    });
};

export const categoryActions = {
  fetch,
  fetchSearch,
};
