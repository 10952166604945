import { SEARCH } from "../types/search.type";

const initialState = {
  search: "",
  year: "",
  categories: "",
  awardtype: "",
  international_award: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH.ADD:
      return { ...action.payload };

    case SEARCH.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
