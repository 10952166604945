/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { leaderBoardAction } from "../actions/leaderBoard.action";
import Layout from "../components/Layout";
import LeaderBoardTable from "../components/LeaderBoardTable";
import Loading from "../components/Loading";
import Error from "../components/Error";

import Img from "../images/most-awarded.png";

export default function LeaderBoard({ match, location }) {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const queries = queryString.parse(location.search);

  React.useMemo(() => {
    if (!state.filter.loading)
      dispatch(
        leaderBoardAction.fetch(
          queries.year
            ? queries.year
            : state.filter.data?.leaderboards_years?.[0].value
        )
      );
  }, [dispatch, state.filter.loading]);

  const handleChange = async (e) => {
    const { value } = e.target;
    window.location.href = `${match.url}?year=${value}`;
  };

  return (
    <Layout>
      <div className="leader-board">
        {state.filter.loading && !state.filter.error && <Loading />}
        {!state.filter.loading && state.filter.error && (
          <Error messages={state.filter.error} />
        )}
        {!state.filter.loading && !state.filter.error && (
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>
                  <img src={Img} alt="Most Awarded" className="img-fluid" />
                  <span>CREATIVE LEADERBOARD</span>
                </h1>
              </div>
              <div className="table-wrapper">
                <div className="action">
                  {!state.filter.loading && (
                    <select
                      onChange={handleChange}
                      value={queries.year ? queries.year : window.DEFAULT_YEAR}
                    >
                      {state.filter.data?.leaderboards_years.map((y, i) => (
                        <option value={y.value} key={i}>
                          {y.name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                <div className="overflow">
                  <LeaderBoardTable />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}
