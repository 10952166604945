/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { categoryActions } from "../actions/category.action";
import { breadcrumbActions } from "../actions/breadcrumb.action";
import Error from "../components/Error";
import Filter from "../components/Filter";
import { searchActions } from "../actions/search.action";
import downloadImg from "../images/download.svg";

export default function Category({ match }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.categories);
  const year = match.params.year ? match.params.year : window.DEFAULT_YEAR;
  const [helmet, setHelmet] = React.useState({});

  React.useEffect(() => {
    dispatch(categoryActions.fetch(year));
    dispatch(
      breadcrumbActions.addBreadcrumb({
        items: [],
        current: "Winner Archives Adman " + year,
      })
    );
  }, [dispatch]);

  React.useEffect(() => {
    const title =
      !state.loading && state.error ? "Error" : "Winner Archives" + year;
    setHelmet({ title: title + " - " + window.DEFAULT_TITLE });
    //
  }, [state, dispatch]);

  React.useEffect(() => {
    dispatch(searchActions.resetSearchParams());
  }, []);

  return (
    <Layout title={helmet.title} breadcrumb>
      <Filter />
      <div className="archive-category">
        <h1 className="text-center">
          WINNER ARCHIVES <span>ADMAN {year}</span>
        </h1>
        <div className="download">
          <a
            href={!state.loading && state.data?.download?.file}
            target="_blank"
            rel="noreferrer"
          >
            <img src={downloadImg} alt="" />
            <span>Download</span>
          </a>
        </div>
        {!state.loading && state.error && <Error messages={state.error} />}
        <div className="section-wrapper">
          <div className="action"></div>
          <div className="content">
            <div className="row m-0">
              {!state.loading &&
                !state.error &&
                state.data?.categories.map((o, i) => (
                  <div className="col-md-4 p-0" key={i}>
                    <a
                      href={`/winner-archives/${year}/${o.id}`}
                      className="image-hover scale-in-right"
                    >
                      <div
                        className="bg"
                        style={{ backgroundImage: `url(${o.image})` }}
                        alt={o.name}
                      />
                      <div className="caption">{o.name}</div>
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
