import { LEADER_BOARD } from "../types/leaderBoard.type";

const initialState = {
  loading: true,
  data: [],
  error: "",
};

const leaderBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEADER_BOARD.SUCCESS_FETCH:
      return {
        loading: false,
        data: action.payload,
        error: "",
      };
    case LEADER_BOARD.FAIL_FETCH:
      return {
        loading: false,
        data: [],
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
export default leaderBoardReducer;
