import { Link, NavLink } from "react-router-dom";
import Logo from "../images/logo-adman.png";
import MobileMenu from "./MobileMenu";

export default function Header() {
  return (
    <header className="header">
      <div className="bg"></div>
      <div className="container">
        <div className="row">
          <div className="col-9 col-md-3">
            <Link to="/" className="main-logo">
              <img src={Logo} alt="Adman Logo" />
            </Link>
          </div>
          <div className="col">
            <nav className="main-menu">
              <ul>
                <li>
                  <NavLink activeClassName="active" to="/" exact>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/winner-archives" activeClassName="active">
                    Winner Archives
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/leader-board-update"
                    activeClassName="active"
                    exact
                  >
                    Most Awarded Creative Leaderboard
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://admanawards.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Adman Awards Website
                  </a>
                </li>
              </ul>
            </nav>

            <MobileMenu />
          </div>
        </div>
      </div>
    </header>
  );
}
