import React from "react";
import admanLogo from "../images/footer-adman-logo.jpg";
import aatLogo from "../images/aat-logo.png";
import ceaLogo from "../images/cea-logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <section className="pre">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="logos">
                <a href="/">
                  <img src={admanLogo} alt="Adman" />
                </a>
                <a href="/">
                  <img src={aatLogo} alt="" />
                </a>
                <a href="/">
                  <img src={ceaLogo} alt="" style={{ maxHeight: 40 }} />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-link">
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/term-of-use">Term of Use</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="copy">
                © Copyright 2018 All rights reserved by SACICT Sitemap
                Disclaimer
              </p>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
