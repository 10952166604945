/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { breadcrumbActions } from "../actions/breadcrumb.action";
import { searchActions } from "../actions/search.action";
import Filter from "../components/Filter";
import Layout from "../components/Layout";
import TextMode from "../components/TextMode";
import VisualMode from "../components/VisualMode";
import { useHistory } from "react-router-dom";
import { singleSubCategoryActions } from "../actions/singleSubCategory.action";

export default function SingleSubCategory({ match }) {
  const year = match.params.year ? match.params.year : window.DEFAULT_YEAR;
  const category = match.params.category;
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter);
  const state = useSelector((state) => state.subCategory);
  const [mode, setMode] = React.useState("VISUAL");
  const history = useHistory();

  React.useEffect(() => {
    dispatch(singleSubCategoryActions.fetch(match.params.subCategory));
    dispatch(searchActions.resetSearchParams());
    dispatch(
      breadcrumbActions.addBreadcrumb({
        items: [
          {
            url: `/winner-archives/${year}`,
            name: "Winner Archives Adman " + year,
          },
        ],
        // current: !state.loading && state.data.categories.name,
      })
    );
  }, [dispatch, state.loading]);

  return (
    <Layout breadcrumb>
      <Filter />
      {!state.loading && (
        <div
          className={classNames(
            "archive-sub-categories",
            mode === "TEXT" && "black"
          )}
        >
          <div className="container">
            <div className="row justify-content-end">
              {/* {state.data?.categories && (
                <div className="col-12">
                  <h1 className="text-right">
                    {state.data.categories.name}
                    {state.data.categories.sub_title && (
                      <span>{state.data.categories.sub_title}</span>
                    )}
                  </h1>
                  <p className="description">
                    {state.data.categories.description}
                  </p>
                </div>
              )} */}
              <div className="col-md-4">
                <div className="years-select">
                  {!filter.loading &&
                    filter.data.years.map((y, i) => (
                      <button
                        key={i}
                        className={classNames(
                          "btn btn-sm",
                          +year === y.value ? "btn-main" : "btn-outline-main"
                        )}
                        onClick={() =>
                          history.push(
                            `/winner-archives/${y.value}/${category}`
                          )
                        }
                      >
                        {y.name}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {mode === "VISUAL" && <VisualMode year={year} category={category} />}
          {mode === "TEXT" && <TextMode year={year} category={category} />}

          <div className="switch-mode">
            <div className="group">
              <button
                className={classNames(
                  "btn-toggle",
                  mode === "TEXT" && "active"
                )}
                onClick={() => setMode("TEXT")}
              >
                TEXT
              </button>
              <button
                className={classNames(
                  "btn-toggle",
                  mode === "VISUAL" && "active"
                )}
                onClick={() => setMode("VISUAL")}
              >
                VISUAL
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
