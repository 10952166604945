/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../components/Filter";
import Layout from "../components/Layout";
import { searchActions } from "../actions/search.action";
import { categoryActions } from "../actions/category.action";
import Error from "../components/Error";
import NoData from "../components/NoData";

const SearchResult = ({ location }) => {
  const queries = queryString.parse(location.search);
  const [helmet, setHelmet] = React.useState({});
  const state = useSelector((state) => state.categories);
  const filter = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setHelmet({
      title: "Search Result - " + window.DEFAULT_TITLE,
    });
  }, [location]);

  React.useEffect(() => {
    searchActions.addSearchParams(dispatch, queries);
  }, [dispatch]);

  React.useEffect(() => {
    categoryActions.fetchSearch(dispatch, location.search);
  }, [dispatch, location.search]);

  return (
    <Layout title={helmet.title}>
      <Filter />
      <div className="archive-category">
        <h1 className="text-center">
          Search Result for{" "}
          {!state.loading && (
            <>
              <span>
                "
                {[
                  queries.search,
                  queries.year,
                  !state.loading && state.data.categories,
                  !state.loading && state.data.awardtype,
                  queries.international_award,
                ]
                  .filter((o) => o !== "")
                  .join(`" "`)}
                "
              </span>
            </>
          )}
        </h1>
        {!state.loading && state.error && <Error messages={state.error} />}
        <div className="section-wrapper">
          <div className="action"></div>
          <div className="content">
            <div className="row m-0">
              {!filter.loading &&
                filter.data.years.map((y, j) => (
                  <React.Fragment key={j}>
                    {state.data.entries?.[y.name] && (
                      <h2 className="search-header">{y.name}</h2>
                    )}
                    {!state.loading &&
                      !state.error &&
                      state.data.entries?.[y.name] &&
                      state.data.entries[y.name].map((o, i) => (
                        <div className="col-md-4 p-0" key={i}>
                          <a
                            href={`/winner-archives/${y.name}/${o.id_categories}/${o.id_subcategories}/${o.id}`}
                            className="image-hover scale-in-right"
                          >
                            <div
                              className="bg"
                              style={{
                                backgroundImage: `url(${o.image[0]})`,
                              }}
                              alt={o.name}
                            />
                            <div className="caption">{o.title}</div>
                          </a>
                        </div>
                      ))}
                  </React.Fragment>
                ))}

              {!state.loading &&
                !state.error &&
                state.data.entries &&
                state.data.entries.length === 0 && (
                  <NoData
                    text={
                      !state.loading && (
                        <>
                          "
                          {[
                            queries.search,
                            queries.year,
                            !state.loading && state.data.categories,
                            !state.loading && state.data.awardtype,
                            queries.international_award,
                          ]
                            .filter((o) => o !== "")
                            .join(`" "`)}
                          "
                        </>
                      )
                    }
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SearchResult;
