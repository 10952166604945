import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "./Header";
import Breadcrumb from "./Breadcrumb";
import Wrapper from "./Wrapper";
import Footer from "./Footer";

function Layout(props) {
  return (
    <div className="layout">
      <HelmetProvider>
        <Helmet>
          <title>{props.title ? props.title : window.DEFAULT_TITLE}</title>
        </Helmet>
        <Header />
        {props.breadcrumb && <Breadcrumb />}
        <Wrapper
          component={props.children}
          classes={props.breadcrumb ? "breadcrumb-active" : null}
        />
        {!props.disableFooter && <Footer />}
      </HelmetProvider>
    </div>
  );
}

export default Layout;
