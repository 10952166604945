export const subString = (text) => {
  if (!text) return { code: "", title: "" };
  const code = text.substring(0, 3);
  const title = text.substring(3, text.length);
  return { code, title };
};

export const youTubeGetID = (url) => {
  if (!url) return;

  const [a, , b] = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (b !== undefined) {
    return b.split(/[^0-9a-z_-]/i)[0];
  } else {
    return a;
  }
};
