/* eslint-disable no-useless-computed-key */
import classNames from "classnames";
import React from "react";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import IconSearch from "../images/icon-search.svg";
import { searchActions } from "../actions/search.action";

const Dropdown = ({
  label,
  options,
  value,
  open,
  onClick,
  onChange,
  isClearFields,
}) => {
  return (
    <div
      className={classNames(
        "dropdown",
        open && !isClearFields && "open fade-in"
      )}
    >
      <div className={"dropdown-value"}>
        <button type="button" onClick={() => onClick(!open)}>
          {label}
        </button>
      </div>

      {open && !isClearFields && (
        <div className="dropdown-option">
          <div className="over-flow">
            {options.map((o, i) => (
              <button
                type="button"
                key={i}
                onClick={() => onChange(o.value)}
                className={classNames(
                  value.toString() === o.value.toString() && "active"
                )}
              >
                {o.name}
              </button>
            ))}
          </div>
        </div>
      )}
      {!open && isClearFields && (
        <div className="border-d">
          <span />
        </div>
      )}
    </div>
  );
};

const FilterBox = ({ open, onClose }) => {
  const state = useSelector((state) => state.filter);
  const values = useSelector((state) => state.searchParams);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState({});
  const [clearFields, setClearFields] = React.useState(true);

  const dispatch = useDispatch();

  const handleToggleDropdown = (index) => {
    setIsDropdownOpen({
      [index]: !isDropdownOpen[index],
    });
    setClearFields(false);
  };

  const handleClear = () => {
    dispatch(searchActions.resetSearchParams());
    setIsDropdownOpen({});
    setClearFields(true);
  };

  const handleChangeValue = (name, value) => {
    console.log(value);
    const payload = { ...values, [name]: value };
    dispatch(searchActions.addSearchParams(payload));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(searchActions.addSearchParams(values));
    window.location.href = `/search?${queryString.stringify(values)}`;
  };

  return (
    <div className={classNames("filter-box", open && "slide-to-right")}>
      <div className="box">
        <button className="back" onClick={onClose}>
          Back
        </button>
        <h4>Filter</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <img src={IconSearch} alt="" />
              </div>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleChangeValue("search", e.target.value)}
                value={values.search}
              />
            </div>
          </div>
          <Dropdown
            label="Year"
            open={isDropdownOpen[0]}
            options={(!state.loading && state.data.years) || []}
            isClearFields={clearFields}
            onClick={() => handleToggleDropdown(0)}
            onChange={(value) => handleChangeValue("year", value)}
            value={values.year}
          />
          <Dropdown
            label="Category"
            open={isDropdownOpen[1]}
            options={(!state.loading && state.data.categories) || []}
            isClearFields={clearFields}
            onClick={() => handleToggleDropdown(1)}
            onChange={(value) => handleChangeValue("categories", value)}
            value={values.categories}
          />
          <Dropdown
            label="Award Type"
            open={isDropdownOpen[2]}
            options={(!state.loading && state.data.awards_type) || []}
            isClearFields={clearFields}
            onClick={() => handleToggleDropdown(2)}
            onChange={(value) => handleChangeValue("awardtype", value)}
            value={values.awardtype}
          />

          <Dropdown
            label="International Award "
            open={isDropdownOpen[3]}
            options={(!state.loading && state.data.international_award) || []}
            isClearFields={clearFields}
            onClick={() => handleToggleDropdown(3)}
            onChange={(value) =>
              handleChangeValue("international_award", value)
            }
            value={values.international_award}
          />

          {/* <button type="button">International Award</button> */}

          <div className="actions">
            <button type="submit" className="btn btn-block btn-white">
              Submit
            </button>
            <button
              type="reset"
              className="btn btn-block btn-white"
              onClick={handleClear}
            >
              Clear
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Filter = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <button className="toggle-filter" onClick={() => setIsOpen(true)}>
        <span className="zoom-1 animation-delay-1" />
        <span className="zoom-1 animation-delay-2" />
        <span className="zoom-1 animation-delay-3" />
      </button>
      {isOpen && (
        <div
          role="button"
          className="overlay"
          onClick={() => setIsOpen(false)}
        />
      )}
      <FilterBox open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default Filter;
